<template>
  <label
    :for="name"
    class="custom-label"
  >
    <span
      v-if="label"
      v-html="labelText"
    />
    <slot />
  </label>
</template>

<script>
  import labelText from '@/mixins/labelText'

  export default {
    mixins: [
      labelText,
    ],

    props: {
      label: {
        type: String,
        default: null,
      },
      name: {
        type: String,
        required: true,
      },
    },
  }
</script>

<style lang="scss">
  .custom-label {
    display: inline-flex;
    padding: 0 0 0.8rem 0;
    color: color(dark-primary);
    line-height: 1;
    font-size: 1.4rem;
    font-weight: 600;
    cursor: pointer;
    &--mark-required {
      &::after {
        content: '*';
      }
    }
  }
</style>
