<template>
  <div>
    <div
      ref="select"
      class="base-select"
      :aria-describedby="descriptionId"
      :class="[
        {
          'base-select--has-error': showError,
          'base-select--is-required': isRequired,
        },
        ...modifiers,
      ]"
      :data-testid="`${dataTestid} base-select`"
    >
      <label
        v-if="label"
        class="base-select__label"
        :aria-hidden="isLabelHidden"
        :class="{
          'base-select__label--is-visually-hidden': isLabelHidden,
        }"
        :data-testid="`${dataTestid}-label base-select-label`"
        :id="`select-label-${uuid}`"
        :for="selectId"
      >
        {{ label }}
      </label>

      <div class="nhsuk-form-group">
        <select
          class="nhsuk-select"
          :aria-describedby="descriptionId"
          :aria-labelledby="ariaLabelledby"
          :aria-required="isRequired"
          :id="name"
          :name="name"
          :data-testid="name"
          v-model="model">
          <option
            v-for="option in transformedOptions"
            :value="option.value"
            :key="option.value">
            {{ option.label }}
          </option>
        </select>
      </div>

    </div>
  </div>
</template>

<script>
  import { capitalize, isString, kebabCase } from 'lodash'

  import forceAnnounce from '@/mixins/forceAnnounce'
  import testid from '@/mixins/testid/testid'

  export default {
    inheritAttrs: false,

    components: {
    },

    mixins: [
      forceAnnounce,
      testid(),
    ],

    props: {
      ariaInvalid: {
        type: Boolean,
        default: false,
      },
      capitalize: {
        type: Boolean,
        default: false,
      },
      config: {
        type: Object,
        default: () => ({}),
      },
      descriptionId: {
        type: [String, Boolean],
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      isLabelHidden: {
        type: Boolean,
        default: false,
      },
      isRequired: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        default: '',
      },
      name: {
        type: String,
        required: true,
      },
      options: {
        type: Array,
        required: true,
      },
      placeholder: {
        type: String,
        default: '',
      },
      showError: {
        type: Boolean,
        default: false,
      },
      trackBy: {
        type: String,
        default: '',
      },
      value: {
        type: [Number, String, Object],
        default: undefined,
      },
    },

    watch: {
      value: {
        handler(newValue) {
          if (newValue && newValue.length === 1) {
            this.model = `0${newValue}`
          } else {
            this.model = newValue
          }
        },
        immediate: true,
      },
      model: 'readSelectedOption',
      ariaInvalid: 'setAriaInvalid',
    },

    data() {
      return {
        isFocused: false,
        selectId: null,
        model: undefined,
      }
    },

    methods: {
      setAriaInvalid() {
        this.ariaInvalid
          ? this.getSelectButton().setAttribute('aria-invalid', this.ariaInvalid)
          : this.getSelectButton().removeAttribute('aria-invalid')
      },
      readSelectedOption(model) {
        this.$emit('change', model)
      },
    },

    computed: {
      transformedOptions() {
        const capitalizeIfneeded = label => (
          this.capitalize && isString(label)
            ? capitalize(label)
            : label
        )

        return this.options?.map(option => ({
          label: capitalizeIfneeded(option),
          value: option,
        }))
      },
      modifiers() {
        return Object.keys(this.$attrs).map(key => `base-select--${kebabCase(key)}`)
      },
      ariaLabelledby() {
        return `select-label-${this.uuid} ${this.selectId}`
      },
    },
  }
</script>

<style lang="scss">
  $selectGroupBlock: 'base-select';

  .#{$selectGroupBlock} {
    padding: 1.8rem 0 0 0;
    position: relative;
    font-size: 1.4rem;

    .nhsuk-select {
      width: 100%;
      height: 4rem;
      padding: 1rem;
      color: color(dark-grey);
      font-size: 1.4rem;
      border: 1px solid color(input-border);
      border-radius: 0;
      background-color: color(_white);
      caret-color: color(input-border);

      &:focus {
        border-color: color(input-border);
        @include outline;
      }
    }
    &--has-error {
      .multiselect__select::before {
        border-color: color(error) transparent transparent;
      }
      &.#{$selectGroupBlock} .multiselect__tags {
        border-color: color(error);
      }
    }
    &__label {
      display: inline-block;
      padding: 0 0 0.8rem 0;
      color: color(dark-primary);
      font-weight: 600;
      line-height: 1;
      cursor: pointer;
      &--is-visually-hidden {
        @include visually-hidden;
      }
    }
  }
  .ios {
    .#{$selectGroupBlock} {
      font-size: 1.6rem !important;
      &__option,
      &__placeholder {
        font-size: 1.6rem !important;
      }
    }
  }
</style>
