var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form-group',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-select',_vm._b({staticClass:"date-select-group",attrs:{"data-testid":_vm.dataTestid,"showError":!!errors.length},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},'date-select',{ descriptionId: _vm.descriptionId, hasHiddenAsterisk: _vm.hasHiddenAsterisk, label: _vm.label, name: _vm.name, required: _vm.required, value: _vm.value },false))]}}])},'form-group',{
    descriptionId: _vm.descriptionId,
    mode: _vm.mode,
    label: _vm.label,
    name: _vm.name,
    testid: _vm.testid,
    validation: _vm.validation,
  },false))}
var staticRenderFns = []

export { render, staticRenderFns }