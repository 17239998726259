<template>
  <form-group
    class="hour-picker-group"
    :data-testid="dataTestid"
    v-bind="{
      label,
      mode,
      name,
      testid,
      validation,
    }"
  >
    <legend class="hour-picker-group__legend">
      {{ labelText }}
    </legend>

    <hour-picker
      v-bind="{
        disabled,
        label,
        name,
      }"
      v-model="model"
    />
  </form-group>
</template>

<script>
  import FormGroup from '@/components/forms/form-group/FormGroup'
  import HourPicker from '@/components/forms/hour-picker-group/HourPicker'

  import addVModel from '@/mixins/addVModel'
  import labelText from '@/mixins/labelText'
  import testid from '@/mixins/testid/testid'

  export default {
    inheritAttrs: false,

    components: {
      FormGroup,
      HourPicker,
    },

    mixins: [
      addVModel('model', String, 'undefined'),
      labelText,
      testid(),
    ],

    props: {
      label: {
        type: String,
        required: true,
      },
      mode: {
        type: [String, Function],
        default: 'aggressive',
      },
      name: {
        type: String,
        required: true,
      },
      required: {
        type: Boolean,
        default: false,
      },
      validation: {
        type: Object,
        default: () => ({}),
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      disabledDates: {
        type: Array,
        default: () => [],
      },
    },
  }
</script>

<style lang="scss">
  .hour-picker-group {
    &__legend {
      padding: 0 0 0.8rem 0;
      color: var(--color-dark-primary);
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 1;
    }
  }
</style>
