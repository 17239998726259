var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"date-select"},[_c('custom-label',_vm._b({staticClass:"date-select__label",attrs:{"hasHiddenAsterisk":!_vm.required && _vm.hasHiddenAsterisk,"label":_vm.labelText}},'custom-label',{ name: _vm.name },false)),_c('div',{staticClass:"date-select__selects-wrapper"},_vm._l((_vm.$static.dateFields),function(ref,key){
var placeholder = ref.placeholder;
var selectLabel = ref.selectLabel;
return _c('base-select',_vm._b({key:key,staticClass:"date-select__select",attrs:{"is-label-hidden":"","aria-invalid":_vm.showError,"config":{ maxHeight: _vm.selectMaxHeight },"isRequired":_vm.required,"label":_vm.i18n(selectLabel),"options":_vm.getOptions(key),"value":_vm.getModelValue(key)},on:{"input":function($event){return _vm.dateChanged($event, key)}}},'base-select',{
        descriptionId: _vm.descriptionId,
        placeholder: placeholder,
        showError: _vm.showError,
        name: _vm.getFieldName(key),
      },false))}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }