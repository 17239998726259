<template>
  <div class="hour-picker">
    <div class="hour-picker__selects-wrapper">
      <base-select
        is-label-hidden
        class="hour-picker__select"
        :label="'Hour'"
        :placeholder="'Hour'"
        :name="`hour_${uuid}`"
        :options="getHoursRange()"
        descriptionId="hour"
        isRequired
        v-model="hour"
        @change="(event) => setTime('hour', event)"
      />
      <base-select
        is-label-hidden
        class="hour-picker__select"
        :placeholder="'Minute'"
        :label="'Minute'"
        :name="`minute_${uuid}`"
        :options="getMinutesRange()"
        descriptionId="minutes"
        isRequired
        v-model="minute"
        @change="(event) => setTime('minute', event)"
      />
    </div>
  </div>
</template>

<script>
  import { isString, padStart } from 'lodash'

  import BaseSelect from '@/components/base/BaseSelect'

  import getCssColorVariable from '@/helpers/getCssColorVariable'

  import addVModel from '@/mixins/addVModel'

  export default {
    components: {
      BaseSelect,
    },

    mixins: [
      addVModel('model'),
    ],

    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        default: null,
      },
      name: {
        type: String,
        required: true,
      },
      required: {
        type: Boolean,
        default: false,
      },
      validation: {
        type: Object,
        default: () => ({}),
      },
    },

    mounted() {
      this.initializeTime()
    },

    data() {
      return {
        hour: undefined,
        minute: undefined,
      }
    },

    methods: {
      initializeTime() {
        if (!isString(this.model)) return

        [this.hour, this.minute] = this.model.split(':')
      },
      setTime(key, value) {
        this[key] = value
        if (this.hour === undefined || this.minute === undefined) return

        this.model = `${this.hour}:${this.minute}`
      },
      getRange(number) {
        return Array(number).fill().map((value, key) => padStart(key, 2, '0'))
      },
      getHoursRange() {
        return this.getRange(24)
      },
      getMinutesRange() {
        return this.getRange(60)
      },
    },

    computed: {
      input() {
        return this.$refs.input.$el.querySelector('input')
      },
      picker() {
        return this.$refs.picker.$el.querySelector('.datetimepicker')
      },
      pickerColumns() {
        return Array.from(this.$refs.picker.$el.querySelectorAll('.time-picker-column > div'))
      },
      color() {
        return getCssColorVariable('primary')
      },
    },
  }
</script>

<style lang="scss">
  .hour-picker {
    &__selects-wrapper {
      display: grid;
      grid-column-gap: 0.4rem;
      grid-template-columns: repeat(2, 1fr);
      @include min-sm {
        display: flex;
        justify-content: space-between;
        justify-content: flex-start;
      }
      .base-select {
        padding: 0;
      }
    }
    &__select {
      min-width: 0;
      @include min-sm {
        width: 8.5rem;
        margin: 0 1rem 0 0;
      }
    }
    .multiselect__option {
      padding-right: 0;
    }
  }
</style>
