<template>
  <div class="date-select">
    <custom-label
      class="date-select__label"
      :hasHiddenAsterisk="!required && hasHiddenAsterisk"
      :label="labelText"
      v-bind="{ name }"
    />

    <div class="date-select__selects-wrapper">
      <base-select
        is-label-hidden
        v-for="({ placeholder, selectLabel }, key) in $static.dateFields"
        :key="key"
        class="date-select__select"
        :aria-invalid="showError"
        :config="{ maxHeight: selectMaxHeight }"
        :isRequired="required"
        :label="i18n(selectLabel)"
        :options="getOptions(key)"
        :value="getModelValue(key)"
        v-bind="{
          descriptionId,
          placeholder,
          showError,
          name: getFieldName(key),
        }"
        @input="dateChanged($event, key)"
      />
    </div>
  </div>
</template>

<script>
  import { isNil } from 'lodash'
  import moment from 'moment'

  import BaseSelect from '@/components/base/BaseSelect'
  import CustomLabel from '@/components/forms/custom-label/CustomLabel'

  import rangeArray from '@/helpers/rangeArray'

  import i18n from '@/mixins/i18n'
  import labelText from '@/mixins/labelText'

  const monthsShort = moment.monthsShort()

  export default {
    components: {
      BaseSelect,
      CustomLabel,
    },

    mixins: [
      i18n,
      labelText,
    ],

    props: {
      descriptionId: {
        type: [String, Boolean],
        default: false,
      },
      hasHiddenAsterisk: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        required: true,
      },
      name: {
        type: String,
        required: true,
      },
      required: {
        type: Boolean,
        default: false,
      },
      showError: {
        type: Boolean,
        default: false,
      },
      value: {
        type: Object,
        default: null,
      },
    },

    mounted() {
      const mediaQuery = window.matchMedia('(min-width: 1024px)')

      mediaQuery.addListener(this.onResize)
      this.onResize(mediaQuery)
    },

    data() {
      return {
        selectMaxHeight: 300,
      }
    },

    methods: {
      getFieldName(field) {
        return `${this.name}_${field}`
      },
      getModelValue(key) {
        return key === 'month'
          ? monthsShort[this.value?.month]
          : this.value?.[key]
      },
      dateChanged(event, key) {
        const newValue = key === 'month'
          ? monthsShort.findIndex(month => month === event)
          : event

        this.emitDate(newValue, key)
      },
      emitDate(newVal, key) {
        const newModel = { ...this.value }

        newModel[key] = newVal
        this.$emit('input', newModel)
      },
      onResize({ matches }) {
        this.selectMaxHeight = matches ? 300 : 200
      },
    },

    computed: {
      daysOptions() {
        const date = moment()
        const { year, month } = this.value || {}

        if (year) date.year(year)
        if (!isNil(month)) date.month(month)

        const daysNumber = date.daysInMonth()
        const days = rangeArray(1, daysNumber, false)

        if (this.value?.day > daysNumber) {
          this.emitDate(undefined, 'day')
        }

        return days
      },
      getOptions() {
        return key => (
          key === 'day'
            ? this.daysOptions
            : this.$static[`${key}sOptions`]
        )
      },
    },

    static() {
      return {
        dateFields: {
          day: {
            placeholder: 'DD',
            selectLabel: 'day-of-birth',
          },
          month: {
            placeholder: 'MM',
            selectLabel: 'month-of-birth',
          },
          year: {
            placeholder: 'YYYY',
            selectLabel: 'year-of-birth',
          },
        },
        monthsOptions: monthsShort,
        yearsOptions: rangeArray(moment().year(), 1900, false),
      }
    },

    slug: 'component.forms.date-select-group.date-select',
  }
</script>

<style lang="scss">
  .date-select {
    padding-top: 1.8rem;
    &__selects-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 0.4rem;
      @include min-sm {
        display: flex;
        justify-content: space-between;
        justify-content: flex-start;
      }
      .base-select {
        padding: 0;
      }
    }
    &__select {
      min-width: 0;
      @include min-sm {
        width: 8.5rem;
        margin: 0 1rem 0 0;
      }
    }
    .multiselect__option {
      padding-right: 0;
    }
  }
</style>
