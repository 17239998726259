var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form-group',_vm._b({staticClass:"hour-picker-group",attrs:{"data-testid":_vm.dataTestid}},'form-group',{
    label: _vm.label,
    mode: _vm.mode,
    name: _vm.name,
    testid: _vm.testid,
    validation: _vm.validation,
  },false),[_c('legend',{staticClass:"hour-picker-group__legend"},[_vm._v(" "+_vm._s(_vm.labelText)+" ")]),_c('hour-picker',_vm._b({model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},'hour-picker',{
      disabled: _vm.disabled,
      label: _vm.label,
      name: _vm.name,
    },false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }