<template>
  <form-group
    v-bind="{
      descriptionId,
      mode,
      label,
      name,
      testid,
      validation,
    }"
    v-slot="{ errors }"
  >
    <date-select
      class="date-select-group"
      :data-testid="dataTestid"
      :showError="!!errors.length"
      v-bind="{ descriptionId, hasHiddenAsterisk, label, name, required, value }"
      v-model="model"
    />
  </form-group>
</template>

<script>
  import DateSelect from '@/components/forms/date-select-group/DateSelect'
  import FormGroup from '@/components/forms/form-group/FormGroup'

  import addVModel from '@/mixins/addVModel'
  import testid from '@/mixins/testid/testid'

  export default {
    components: {
      DateSelect,
      FormGroup,
    },

    mixins: [
      addVModel('model', Object, null),
      testid(),
    ],

    props: {
      hasHiddenAsterisk: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        required: true,
      },
      mode: {
        type: [String, Function],
        default: 'aggressive',
      },
      name: {
        type: String,
        required: true,
      },
      preventFutureDate: {
        type: Boolean,
        default: false,
      },
      required: {
        type: Boolean,
        default: false,
      },
      validation: {
        type: Object,
        default: () => ({}),
      },
    },

    computed: {
      descriptionId() {
        return `error-${this.name}_${this.uuid}`
      },
    },
  }
</script>
