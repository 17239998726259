<template>
  <nav
    class="nhsuk-pagination"
    :data-testid="`${dataTestid} pagination`"
  >
    <p>You are viewing {{ getNumbersResult() }} search results out of {{ totalResults }} total
      <!-- [#47] -->
      <!-- <button
        data-testid="pagination-view-all"
        class="pagination-view-all"
        @click="allResults"
      >View all results</button> -->
    </p>
    <ul class="pagination nhsuk-list nhsuk-pagination__list">
      <li
        class="nhsuk-pagination-item--previous"
        v-if="previousArrowVisible">
        <button
          data-testid="pagination-arrow-previous"
          class="nhsuk-pagination__link--prev base-pagination-button"
          @click="previousPage">
          <div class="base-pagination-button__title-layout">
            <inline-svg
              class="nhsuk-icon nhsuk-icon__arrow-left base-pagination-button__arrow"
              role="presentation"
              src="icons/icon-cheveron-right.svg"
            />
            <span
              class="nhsuk-pagination__title nhsuk-u-font-size-32 base-pagination-button__title">
              {{ i18n('previous') }}
            </span>
          </div>
          <span class="nhsuk-u-visually-hidden">:</span>
          <span
            class="nhsuk-pagination__page nhsuk-u-font-size-22 base-pagination-button__subtitle">
            Search results {{ getNumbersResult('previous') }}
          </span>
        </button>
      </li>
      <li
        class="nhsuk-pagination-item--next"
        v-if="nextArrowVisible">
        <button
          data-testid="pagination-arrow-next"
          class="nhsuk-pagination__link--next base-pagination-button"
          @click="nextPage">
          <div class="base-pagination-button__title-layout">
            <span
              class="nhsuk-pagination__title nhsuk-u-font-size-32 base-pagination-button__title">
              {{ i18n('next') }}
            </span>
            <inline-svg
              class="nhsuk-icon nhsuk-icon__arrow-right base-pagination-button__arrow"
              role="presentation"
              src="icons/icon-cheveron-right.svg"
            />
          </div>
          <span class="nhsuk-u-visually-hidden">:</span>
          <span
            class="nhsuk-pagination__page nhsuk-u-font-size-22 base-pagination-button__subtitle"
          >
            Search results {{ getNumbersResult('next') }}
          </span>
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
  import { mapState } from 'vuex'

  import i18n from '@/mixins/i18n'
  import testid from '@/mixins/testid/testid'

  export default {
    mixins: [
      i18n,
      testid('pagination'),
    ],

    props: {
      firstPage: {
        type: Number,
        required: true,
      },
      lastPage: {
        type: Number,
        required: true,
      },
      currentPage: {
        type: Number,
        required: true,
      },
      totalResults: {
        type: Number,
        required: true,
      },
      itemsPage: {
        type: Number,
        required: true,
      },
    },

    created() {
      const media = window.matchMedia('(max-width: 768px)')
      const setIsMobile = e => {
        this.isMobile = e.matches
      }

      this.isMobile = this.wW < 768

      media.addEventListener('change', setIsMobile)

      this.$once('hook:beforeDestroy', () => {
        media.removeEventListener('change', setIsMobile)
      })
    },

    data() {
      return {
        isMobile: false,
      }
    },

    methods: {
      isCurrent(page) {
        return page == this.currentPage
      },
      previousPage() {
        this.changePage(this.currentPage - 1)
      },
      nextPage() {
        this.changePage(this.currentPage + 1)
      },
      changePage(page) {
        if (page == this.currentPage) return

        this.$emit('pageChanged', `${page}`)
      },
      getAriaLabel(page) {
        return this.isCurrent(page)
          ? this.i18n('Current Page, Page {page}', { page })
          : this.i18n('Goto Page {page}', { page })
      },
      getNumbersResult(key) {
        let first = this.getFirstItemPage
        let last = this.getLastItemPage > this.totalResults
          ? this.totalResults
          : this.getLastItemPage
        if (key === 'previous') {
          last = first - 1
          first = last - (this.itemsPage - 1)
        }
        if (key === 'next') {
          first = last + 1
          const newLast = first + (this.itemsPage - 1)
          last = newLast > this.totalResults ? this.totalResults : newLast
        }
        return `${first} - ${last}`
      },
      allResults() {
        this.$emit('viewAllResults')
      },
    },

    computed: {
      ...mapState('layout', [
        'wW',
      ]),
      previousArrowVisible() {
        return this.currentPage > this.firstPage
      },
      nextArrowVisible() {
        return this.getLastItemPage < this.totalResults
      },
      pageOffset() {
        return this.isMobile ? 2 : 4
      },
      maxVisblePages() {
        return (2 * this.pageOffset) + 1
      },
      minimalLastVisiblePage() {
        return Math.min(this.lastPage, this.maxVisblePages)
      },
      firstAndLastPage() {
        let preferredFirstNumber
        let last

        const preferredEndNumber = this.currentPage + this.pageOffset

        // Check if preferredEndNumber hasn't exceeded the range
        if (preferredEndNumber > this.lastPage) {
          last = this.lastPage
          preferredFirstNumber = this.lastPage - (this.pageOffset * 2)
        } else {
          last = preferredEndNumber
          preferredFirstNumber = this.currentPage - this.pageOffset
        }

        // Determine if first number doesn't need the offset
        const first = preferredFirstNumber > 0
          ? preferredFirstNumber
          : this.firstPage

        // Determine if end offset isn't too short
        last = last < this.minimalLastVisiblePage
          ? this.minimalLastVisiblePage
          : last

        return [first, last]
      },
      pageNumbers() {
        const [first, last] = this.firstAndLastPage

        return Array(last - first + 1)
          .fill()
          .map((el, key) => first + key)
      },
      isNavigationDisabled() {
        return this.pageNumbers.length <= 1 || undefined
      },
      getFirstItemPage() {
        return (this.currentPage - 1) * this.itemsPage + 1
      },
      getLastItemPage() {
        return this.getFirstItemPage + this.itemsPage - 1
      },
    },

    slug: 'component.base.base-pagination',
  }
</script>

<style lang="scss">
  $block: 'base-pagination-button';

  .#{$block} {
    &.nhsuk-pagination__link--prev {
      .base-pagination-button__title-layout {
        justify-content: flex-start;
      }
      .base-pagination-button__arrow {
        transform: scaleX(-1);
      }
      .base-pagination-button__title {
        padding-left: 0;
      }
    }
    &.nhsuk-pagination__link--next {
      .base-pagination-button__title {
        padding-right: 0;
      }
    }
    display: inline-block;
    max-width: 22rem;
    text-align: right;
    & &__title-layout {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    & &__title {
      font-size: 1.8rem;
      font-weight: 800;
    }
    & &__arrow {
      width: 2.6rem;
      svg {
        width: 4rem;
        height: 4rem;
        fill: color('primary');
      }
    }
    & &__subtitle {
      font-weight: 600;
    }
  }
  .nhsuk-pagination {
    .pagination-view-all {
      color: color(primary);
      text-decoration: underline;
    }
  }

  .pagination {
    margin-top: 1rem;
  }
</style>
